<template>
  <div>
    <section v-if="isError && !product" class="product-list-heading text-center my-4">
      <img src="@/assets/img/page-not-found.png" alt="Product not found" />
      <p class="mt-4 mb-0"><b>Oops, product not found</b></p>
      <p>
        Please check the url again or
        <router-link to="/">go back to homepage</router-link>
      </p>
      <RecommendedProducts />
    </section>
    <section class="product-list-heading" v-else>
      <div class="box-product-detail pt-4">
        <div class="product-detail pb-3">
          <div class="container">
            <!-- // TODO dynamic announcement banner -->
            <UrlHandler v-if="announcementBanner" class="d-none d-lg-block position-relative" :url="announcementBanner.url">
              <b-img-lazy :src="announcementBanner.image_url" :title="announcementBanner.name" :alt="announcementBanner.name" fluid />
            </UrlHandler>
            <!-- // TODO dynamic announcement banner -->
            <UrlHandler v-if="announcementBanner" class="d-lg-none" :url="announcementBanner.url">
              <b-img-lazy :src="announcementBanner.image_mobile_url" :title="announcementBanner.name" :alt="announcementBanner.name" fluid />
            </UrlHandler>

            <div class="row">
              <div class="col-md-6 mb-5">
                <b-skeleton-wrapper :loading="isLoadingData.product">
                  <template #loading>
                    <div class="d-flex mt-3">
                      <b-skeleton width="80px"></b-skeleton>
                      <b-skeleton class="ml-2" width="80px"></b-skeleton>
                      <b-skeleton class="ml-2" width="80px"></b-skeleton>
                    </div>
                  </template>
                  <div class="breadcrumb" v-if="product">
                    <router-link to="/">Home</router-link>
                    <i class="icon-next"></i>
                    <router-link :to="simpleSlug(product.category1_slug, product.category2_slug, product.category3_slug, product.category3_id)">
                      {{ breadCrumbs.category_3_name }}
                    </router-link>
                    <i class="icon-next"></i>
                    <span>{{ product.name }}</span>
                  </div>
                </b-skeleton-wrapper>

                <div class="left-col">
                  <b-skeleton-wrapper :loading="isLoadingData.product" class="mt-3">
                    <template #loading>
                      <div class="d-md-flex flex-row-reverse for-detail-slider justify-content-end">
                        <div class="w-100 ml-lg-2">
                          <b-skeleton-img width="100%" height="100%" aspect="1:1"></b-skeleton-img>
                        </div>
                        <div class="d-flex flex-lg-column skeleton-product-thumbnails mt-2 mt-lg-0">
                          <b-skeleton-img aspect="1:1" />
                          <b-skeleton-img aspect="1:1" />
                          <b-skeleton-img aspect="1:1" />
                        </div>
                      </div>
                    </template>
                    <div class="d-md-flex flex-row-reverse for-detail-slider justify-content-end" v-if="product">
                      <VueSlickCarousel
                        ref="sliderProduct"
                        @beforeChange="syncSliders"
                        v-bind="sliderProductDetailSlickSettings"
                        class="slider-product-detail"
                      >
                        <ImageZoom v-for="(productFull, index) in product.product_images.filter(el => el.full_image_url)" :key="index"
                          :src="productFull.raw_image_url || productFull.full_image_url"
                          :alt="product.name"
                          :active_promo_badge_url="product.active_promo_badge_url"
                        />
                      </VueSlickCarousel>
                      <!-- THUMBNAILS -->
                      <VueSlickCarousel
                        ref="thumbnailProduct"
                        @beforeChange="syncSliders"
                        v-bind="sliderNavThumbnailSlickSettings"
                        class="slider-nav-thumbnails mt-lg-0 mt-1"
                      >
                        <b-img
                          v-for="(productThumbnail, index) in product.product_images.filter(el => el.thumbnail_image_url)"
                          :key="index"
                          fluid-grow
                          class="rounded cursor-pointer"
                          :src="productThumbnail.thumbnail_image_url"
                          :alt="`Thumbnail ${index + 1} ${setProductImgAltText(product)}`"
                          @error="replaceByDefaultImage"
                        />
                      </VueSlickCarousel>
                    </div>
                  </b-skeleton-wrapper>
                </div>
              </div>
              <div class="col-md-6 px-2">
                <div class="container-lg boxlist-product pl-lg-4 ml-0">
                  <div class="card">
                    <div class="card-body boxlist-bottom pt-3 pb-0">
                      <b-skeleton-wrapper :loading="isLoadingData.product">
                        <template #loading>
                          <b-skeleton width="100px" id="skeleton-brand-name"></b-skeleton>
                          <b-skeleton width="250px" height="28px" class="mt-2" id="skeleton-product-name"></b-skeleton>
                          <b-skeleton width="180px" class="mt-2" id="skeleton-product-rating"></b-skeleton>
                          <b-skeleton width="110px" class="mt-2" id="skeleton-product-price"></b-skeleton>
                          <b-skeleton width="55px" class="mt-2" id="skeleton-product-badge"></b-skeleton>
                          <b-skeleton height="70px" class="mt-2 greatest-voucher-card-skeleton" id="skeleton-product-greatest-voucher"></b-skeleton>
                          <div class="row mt-3">
                            <div class="col">
                              <div class="row align-items-center">
                                <div class="col-md-auto">
                                  <b-skeleton width="40px" id="skeleton-product-color-label"></b-skeleton>
                                </div>
                                <div class="col d-flex mt-lg-0 mt-1">
                                  <b-skeleton type="avatar" size="40px" :class="{ 'ml-1': index > 1 }" v-for="index in 3" :key="index" id="skeleton-product-color-label"></b-skeleton>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6 mt-lg-0 mt-3">
                              <div class="row align-items-center">
                                <div class="col-md-auto">
                                  <b-skeleton width="40px" id="skeleton-product-size-label"></b-skeleton>
                                </div>
                                <div class="col mt-lg-0 mt-1">
                                  <b-skeleton height="40px" id="skeleton-product-size-option"></b-skeleton>
                                </div>
                              </div>
                            </div>
                          </div>
                                                
                          <div class="row align-items-center justify-content-center flex-lg-nowrap mt-3">
                            <div class="col-md-5">
                              <b-skeleton height="40px" type="input" id="skeleton-product-input-qty"></b-skeleton>
                            </div>
                            <div class="col-md-7 mt-lg-0 mt-2">
                              <b-skeleton height="40px" width="100%" type="button" id="skeleton-product-button-buy"></b-skeleton>
                            </div>
                          </div>

                          <div class="d-flex justify-content-between flex-no-wrap mt-3">
                            <div class="d-flex align-items-center justify-content-center flex-grow-1" v-for="index in 3" :key="index">
                              <b-skeleton type="button" width="25px" height="25px" id="product-detail-additional-button-icon"></b-skeleton>
                              <b-skeleton width="60px" class="mw-100 mb-0 ml-1" id="product-detail-additional-button-text"></b-skeleton>
                            </div>
                          </div>

                          <b-skeleton width="150px" id="skeleton-product-details" class="mt-3 mb-2"></b-skeleton>
                          <div class="row">
                            <div class="col-md-6 d-flex align-items-center justify-content-between mt-1" v-for="index in 6" :key="index">
                              <b-skeleton width="100px" id="skeleton-product-details-label"></b-skeleton>
                              <b-skeleton width="60px" id="skeleton-product-details-value"></b-skeleton>
                            </div>
                          </div>

                          <b-skeleton width="150px" id="skeleton-product-description" class="mt-3 mb-2"></b-skeleton>
                          <b-skeleton :width="`${skeletonWidth}px`" class="mt-1 mw-100" v-for="(skeletonWidth, index) in [350, 200, 250, 230]" :key="index"></b-skeleton>

                          <!-- TODO Product brand info skeleton, pake desain yang baru -->
                          <div class="d-flex align-items-center mt-3">
                            <b-skeleton type="avatar" size="40px"></b-skeleton>
                            <div class="ml-2">
                              <b-skeleton width="80px" class="mt-1"></b-skeleton>
                              <div class="d-flex">
                                <b-skeleton width="40px"></b-skeleton>
                                <b-skeleton class="ml-1" width="80px"></b-skeleton>
                              </div>
                            </div>
                            <b-skeleton class="ml-auto" type="button"></b-skeleton>
                          </div>
                        </template>
                        <template v-if="product">
                          <div class="store-closed-box store-closed-box--product-detail mb-3" v-if="product?.seller.is_closed">
                            <fawesome-pro variant="far" icon="exclamation-triangle" size="lg" class="store-closed-notes-info px-2" />
                            <div class="ml-2">
                              <b>Toko sedang tutup</b>
                              <div class="mt-1">Hey, barang kamu akan di proses pada saat toko buka tanggal <b>{{ formatEndDateClose(product.seller.end_date_close) }}</b> ya! <b class="store-closed-notes-info" v-b-modal.modal-closed-shop-info v-if="product?.seller?.closed_notes">Selengkapnya</b></div>
                            </div>
                          </div>
                          <h2 class="roboto" style="font-size: 1.1rem">{{ product.brand_name }}</h2>
                          <h3 class="mb-1">{{ product.name }}</h3>
                          <div v-if="product.rating.average > 0" class="pb-3 text-warning">
                            <div class="border-right pr-2 mr-1 d-inline-block">
                              <fawesome-pro
                                v-for="i in 5" :key="i"
                                :variant="product.rating.average - i > -1 ? 'fas' : 'far'"
                                :icon="product.rating.average - i > -1 && product.rating.average - i < 0 ? 'star-half-alt' : 'star'"
                                class="pr-1"
                              />
                              {{ (Math.round(product.rating.average * 10) / 10).toFixed(1) }}
                            </div>
                            {{ product.rating.average == 5
                              ? "Outstanding" : product.rating.average >= 4
                              ? "Lovely" : product.rating.average >= 3
                              ? "Pretty good" : product.rating.average >= 2
                              ? "Could be better" : "Not satisfying" }}
                          </div>
                          <div v-else class="pb-3">
                            <small class="text-grey">No reviews yet</small>
                          </div>
                          <template v-if="product.status_purchaseable">
                            <div class="product-price text-left mb-2" :class="{'sale-price': (product.product_type == 4 && product.discount_active_bundle) || product.discount_active || product.bundle_dynamic_price }">
                              <template v-if="product.checkout_type == 1">{{ formatPrice(product.price_display, null) }} LizPoints</template>
                              <template v-else>
                                {{ product.bundle_dynamic_price ? $helpers.product.formatBundlePrice(product) : $helpers.format.price(product.price_display) }}
                                <span class="old-price" v-if="product.product_type == 4 && product.discount_active_bundle">{{ $helpers.product.formatBundlePriceOrigin(product) }}</span>
                                <span class="old-price" v-else-if="product.discount_active">{{ $helpers.format.price(product.price) }}</span>
                              </template>
                            </div>
                        
                            <template v-if="product.batch_po">
                              <!-- PO Batch Before Start -->
                              <small v-if="currentTime < product.start_date_po">
                                <span class="mr-1">Starts in</span>
                                <span class="product-status-badge instock-badge mr-1">
                                  <font-awesome icon="clock" class="mr-1"/>
                                  <b>{{ countdownDuration("days") }}</b> D
                                  <b>{{ countdownDuration("hours") }}</b> H
                                  <b>{{ countdownDuration("minutes") }}</b> M
                                </span>
                              </small>
                              <!-- PO Batch End -->
                              <small
                                class="product-status-badge outstock-badge mr-1"
                                v-else-if="currentTime > product.end_date_po"
                              >
                                <font-awesome icon="clock" class="mr-1" />
                                <span>Closed order</span>
                              </small>
                              <!-- PO Batch Active -->
                              <small v-else>
                                <span class="mr-1">Ends in</span>
                                <span class="product-status-badge unavailable-badge mr-1">
                                  <font-awesome icon="clock" class="mr-1"/>
                                  <b>{{ countdownDuration("days") }}</b> D
                                  <b>{{ countdownDuration("hours") }}</b> H
                                  <b>{{ countdownDuration("minutes") }}</b> M
                                </span> 
                              </small>
                            </template>
                            <!-- <div id="product-stock" class="d-inline-block"> -->
                              <span
                                id="product-stock"
                                class="product-status-badge"
                                :class="[
                                  variantStock <= 0
                                    ? 'outstock-badge' // Out of Stock Product
                                    : !product.status_stock
                                    ? 'preorder-badge' // Pre Order Product
                                    : 'instock-badge', // In Stock Product
                                ]"
                              >
                                <template v-if="variantStock <= 0">Sold Out</template>
                                <template v-else-if="!product.status_stock">Pre-order</template>
                                <template v-else>In Stock</template>
                              </span>
                              <template v-if="variantStock > 0">
                                <small class="ml-1 bold">
                                  {{
                                    !product.status_stock
                                      ? `${product.ready_stock_duration} Day(s)`
                                      : `Available: ${variantStock}`
                                  }}
                                </small>
                              </template>
                            <!-- </div> -->

                            <div class="product-detail-voucher-badge cursor-pointer my-2" v-if="product.greatest_voucher_discount" v-b-modal.modal-available-voucher>
                              <b-img :src="require('@/assets/img/voucher_discount-01.svg')" alt="" />
                              <div class="d-flex flex-column justify-content-center flex-grow-1 px-2">
                                <span class="font-14px line-clamp-1">Kamu bisa hemat hingga <span class="text-gold">{{ product.greatest_voucher_discount.discount_type == 1 ? $helpers.format.price(product.greatest_voucher_discount.discount) : `${product.greatest_voucher_discount.discount}%` }}</span></span>
                                <span class="font-11px text-grey line-clamp-1">Masih ada {{ product.available_voucher.length }} voucher lainnya!</span>
                              </div>
                              <fawesome-pro variant="fas" icon="chevron-right" class="text-gold pr-3" />
                            </div>

                            <template v-if="showInstallment(product) && installmentCalculator.length > 0">
                              <div class="installment-calculator my-2 cursor-pointer text-white" v-b-modal.modal-installment-calculator>
                                <span class="line-clamp-1">
                                  Cicilan {{ lowestInstallment?.lowest_installment.tenure }} Bulan, <b>{{ $helpers.format.price(lowestInstallment?.lowest_installment.monthly_instalment) }}</b> /bulan
                                </span>
                                <fawesome-pro variant="far" icon="chevron-right" class="ml-2" />
                              </div>
                            </template>
                            <!-- Calculate Commission for Whizliz Associate -->
                            <h5
                              v-if="
                                product.checkout_type != 1 &&
                                product.reseller_commission_enable &&
                                product.reseller_commission_upto > 0
                              "
                              class="installment product-commission-price text-left mt-2"
                            >
                              Get commission up to <b>{{ formatPrice(product.reseller_commission_upto) }}</b>
                            </h5>
                          </template>
                          <form @submit.prevent="handleSubmit" v-if="!isLoadingData.product" class="mt-3">
                            <template v-if="product.product_bundle">
                              <hr />
                              <p class="m-0">Choose {{product.product_bundle.length}} products<br/>
                              <template v-if="product.product_bundle.filter(el => el.submitted).length > 0">
                                <div class="roboto-bold custom-font-24px" :class="!product.discount_active_bundle ? 'text-black' : 'text-gold'">
                                  {{ formatPrice(bundleTotalPriceDisplay) }}
                                  <span class="old-price" v-if="product.discount_active_bundle && bundleTotalPriceDisplay < bundleTotalPrice">{{ (total => total > 0 ? formatPrice(total) : '')(bundleTotalPrice) }}</span>
                                </div>
                                <small class="text-success" v-if="product.discount_active_bundle && bundleTotalPriceDisplay < bundleTotalPrice">{{ (saved => saved > 0 ? `Save ${formatPrice(saved)}` : '')(bundleSaveAmount) }}</small>
                              </template>
                              </p>
                              <div class="row no-gutters row-cols-2 row-cols-lg-3 bundling-prod-container overflow-x-styled">
                                <div class="col p-1" v-for="(bundle, i) in product.product_bundle" :key="i">
                                  <div class="bundling-prod-card">
                                    <div v-if="!bundle.submitted" class="bundling-prod-box" title="Select a product"  @click="productToPass = product; setBundleChoiceIndex(product.product_bundle, bundle, i)" v-b-modal.modal-add-to-bundle></div>
                                    <!-- <div v-if="!bundle.submitted" class="bundling-prod-box" title="Select a product" @click="bundlingIndex = i; productToPass = product" v-b-modal.modal-add-to-bundle></div> -->
                                    <div v-else class="bundling-prod-body">
                                      <div class="bundling-prod-img">
                                        <b-img-lazy fluid :src="bundle.submitted.product_images[0].medium_image_url"
                                          :title="bundle.submitted.name"
                                          :alt="bundle.submitted.name"
                                          @click.native="bundlingIndex = i; productToPass = product"
                                          @error.native="replaceByDefaultImage"
                                          v-b-modal.modal-add-to-bundle
                                        />
                                        <router-link class="bundling-prod-view" :to="`/product/${bundle.submitted.slug}`" target="_blank">
                                          <img src="@/assets/img/zoom-product.svg" alt="" title="Go to product page" width="24" />
                                        </router-link>
                                      </div>
                                      <div class="bundling-prod-info p-2">
                                        <label class="d-block">
                                          <b>{{bundle.submitted.brand_name}}</b><br/>
                                          {{bundle.submitted.name}}
                                        </label>
                                        <div class="text-center">
                                          <div :class="bundle.submitted.price_display < bundle.submitted.price ? 'text-gold' : 'text-black'">
                                            <span class="roboto-bold">{{ formatPrice(bundle.submitted.price_display) }}</span>  
                                            <span v-if="bundle.submitted.price_display < bundle.submitted.price" class="old-price custom-font-11px mt-1">{{ formatPrice(bundle.submitted.price) }}</span> 
                                          </div>
                                          <small class="text-grey d-inline-block mt-2" v-if="bundle.submitted.category_name.indexOf('Fine') < 0">
                                            Color: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)(bundle.submitted.product_detail_variance.find(el => el.id == bundle.submitted.selectedVariant).color.name) }}</b><br/>
                                            <template v-if="detectRingSizes(bundle.submitted)">
                                              Size: <b>{{ (name => name.charAt(0) == '-' ? '-' : name)(bundle.submitted.product_detail_variance.find(el => el.id == bundle.submitted.selectedVariant).size.international) }}</b>
                                            </template>
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template v-else-if="product.product_type != 4">
                              <div
                                class="row size"
                                v-if="productType.indexOf('Fine') < 0"
                              >
                                <div class="col d-flex">
                                  <div
                                    class="panel-warning d-flex align-items-center"
                                  >
                                    <span
                                      class="fas fa-exclamation-square fa-lg text-warning pr-2"
                                    ></span>
                                    Please choose a color first!
                                  </div>
                                  <div class="row align-items-center">
                                    <div class="col-md-auto">
                                      <label class="mb-0">Color</label>
                                    </div>
                                    <div id="pick-color" class="col my-3 my-lg-0">
                                      <label
                                        class="radio-container"
                                        v-for="(productColor, index) in colors"
                                        :key="index"
                                      >
                                        <input
                                          type="radio"
                                          name="radio"
                                          :value="productColor.id"
                                          @click="colorChange($event); setSelectedColor($event)"
                                        />
                                        <span
                                          class="checkmark overflow-hidden"
                                          style="color: black"
                                          :style="{
                                            '--background': productColor.color,
                                          }"
                                        >
                                          <template
                                            v-if="
                                              productColor.name.split(' ').length == 1
                                            "
                                          >
                                            {{
                                              productColor.name.charAt(0) != "-"
                                                ? productColor.name
                                                : "-"
                                            }}
                                          </template>
                                          <template v-else>
                                            {{
                                              productColor.name
                                                .split(" ")
                                                .map((el) => el.charAt(0))
                                                .join("")
                                            }}
                                          </template>
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="productType.indexOf('Ring') >= 0"
                                  class="col-md-7"
                                >
                                  <div class="row align-items-center">
                                    <div class="col-md-3">
                                      <label class="mb-0">Size</label>
                                      <fawesome-pro variant="fal" icon="info-circle" class="category-size-info" v-b-modal.modal-category-size v-if="product.category_size.length" @click.native="categorySize = product" />
                                    </div>
                                    <div class="col-md-9 d-flex">
                                      <div
                                        id="pick-size"
                                        class="styled-select w-100"
                                        @click="triggerColor"
                                      >
                                        <select
                                          id="size-select"
                                          class="w-100"
                                          property="voucherCategoryClass"
                                          @change="sizeChange($event); setSelectedSize($event)"
                                          :disabled="sizes.length == 0"
                                        >
                                          <option selected disabled value="0">
                                            Choose Size
                                          </option>
                                          <option
                                            v-for="(size, index) in sizes"
                                            :key="index"
                                            :value="size.product_variant_id"
                                            :disabled="
                                              product.product_detail_variance.find(
                                                (el) =>
                                                  el.id ==
                                                  parseInt(size.product_variant_id)
                                              ).total_stock == 0
                                            "
                                          >
                                            {{ size.international }}
                                            <template
                                              v-if="
                                                product.product_detail_variance.find(
                                                  (el) =>
                                                    el.id ==
                                                    parseInt(size.product_variant_id)
                                                ).total_stock == 0
                                              "
                                            >
                                              - Out of stock
                                            </template>
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </template>
                            <div class="row align-items-center justify-content-center justify-content-lg-start flex-lg-nowrap text-nowrap" v-if="product.status_purchaseable">
                              <!-- <button class="btn btn-wz-proceed hvr-ripple-out mr-4">Add to bag</button> -->
                              <div class="col-md-5 mt-3">
                                <div id="pick-quantity" class="number m-0">
                                  <span class="minus" @click="reduceQuantity">
                                    <img src="@/assets/img/ic-minus.svg" alt="" />
                                  </span>
                                  <input
                                    type="number"
                                    v-model="productQuantity"
                                    :max="variantStock"
                                    readonly
                                  />
                                  <span class="plus" @click="addQuantity">
                                    <img src="@/assets/img/ic-plus.svg" alt="" />
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-7 mt-3 btn-wz-group">
                                <template v-if="!isMembershipActive && product.bp_exclusive">
                                  <button class="btn btn-wz-buy-now" @click="contactReseller(); $event.preventDefault()">
                                    Contact BP
                                  </button>
                                </template>
                                <template v-else-if="product.product_type == 4">
                                  <button class="btn btn-wz-buy-now" @click="productToPass = product; bundlingIndex = 0; $event.preventDefault()" v-b-modal.modal-add-to-bundle>
                                    Lihat Paket
                                  </button>
                                </template>
                                <template v-else>
                                  <button
                                    id="buy-now"
                                    type="button"
                                    class="btn btn-wz-buy-now btn-block"
                                    :disabled="isLoading || (product.batch_po && currentTime > product.end_date_po) || (product.product_bundle ? product.product_bundle.filter(el => el.submitted).length < product.product_bundle.length : variantStock == 0 || !productVariantId)"
                                    @click="quickCheckout(product, productVariantId, productQuantity)"
                                  >
                                    Buy Now
                                  </button>
                                  <button
                                    id="add-to-bag"
                                    type="submit"
                                    class="btn btn-wz-add-bag btn-block"
                                    :disabled="(product.batch_po && currentTime > product.end_date_po) || (product.product_bundle ? product.product_bundle.filter(el => el.submitted).length < product.product_bundle.length : variantStock == 0 || !productVariantId)"
                                  >
                                    {{ product.checkout_type == 0 ? 'ADD TO BAG' : 'REDEEM' }}
                                  </button>
                                </template>
                              </div>
                              <div class="col-auto mt-3">
                                <font-awesome :class="{'opacity-0' : !isLoading}" icon="spinner" spin />
                              </div>
                            </div>
                            <div class="row no-gutters mt-lg-3">
                              <!-- <div
                                id="catalog"
                                v-if="product.checkout_type == 0 && product.status_purchaseable && isMembershipActive"
                                class="col-lg col-6"
                              >
                                <button
                                  type="button"
                                  class="btn btn-wz-link w-100 p-0 d-flex justify-content-center align-items-center text-left"
                                  v-b-modal.modal-manage-catalog
                                >
                                  <img src="@/assets/img/add_to_catalog_regular.svg" alt="" width="20" class="mr-2" />
                                  Catalog
                                </button>
                              </div>
                              <div
                                id="brochure"
                                v-if="product.checkout_type == 0 && product.status_purchaseable && isMembershipActive"
                                class="col-lg col-6"
                              >
                                <button
                                  type="button"
                                  class="btn btn-wz-link w-100 p-0 d-flex justify-content-center align-items-center text-left"
                                  @click="downloadProductBrochure(product.id)"
                                >
                                  <img src="@/assets/img/download_catalog_regular.svg" alt="" width="20" class="mr-2" />
                                  Brochure
                                </button>
                              </div> -->
                              <div
                                id="wishlist"
                                v-if="product.checkout_type == 0 && product.status_purchaseable"
                                class="col"
                              >
                                <button
                                  type="button"
                                  v-if="
                                    $store.getters.getWishlist.find(
                                      (el) => el.id == product.id
                                    )
                                  "
                                  class="btn btn-wz-link w-100 p-0 d-flex justify-content-center align-items-center text-left"
                                  @click="removeFromWishlist(product)"
                                >
                                  <img src="@/assets/img/whishlist_on_recolor.svg" alt="" width="20" class="mr-2" />
                                  Wishlist
                                </button>
                                <button
                                  type="button"
                                  v-else
                                  class="btn btn-wz-link w-100 p-0 d-flex justify-content-center align-items-center text-left"
                                  @click="addToWishlist(product)"
                                >
                                  <img src="@/assets/img/whishlist_off_recolor_regular.svg" alt="" width="20" class="mr-2" />
                                  Wishlist
                                </button>
                              </div>
                              <div v-if="product.checkout_type == 0" class="col">
                                <button
                                  id="chat-product"
                                  type="button"
                                  class="btn btn-wz-link w-100 p-0 d-flex justify-content-center align-items-center text-left"
                                  @click="chatProduct"
                                >
                                  <span class="fa-lg pr-2">
                                    <img src="@/assets/img/chat.png" alt="" style="width: 23px" />
                                  </span>
                                  Chat
                                </button>
                              </div>
                              <div class="col">
                                <b-dropdown
                                  variant="link"
                                  no-caret
                                  class="w-100"
                                  toggle-class="p-0"
                                  right
                                >
                                  <template #button-content>
                                    <button
                                      id="share-product"
                                      type="button"
                                      class="btn btn-wz-link btn-block p-0"
                                    >
                                      <img src="@/assets/img/share_regular.svg" alt="" width="20" class="mr-2" />
                                      Share
                                    </button>
                                  </template>
                                  <a
                                    target="_blank"
                                    :href="
                                      'https://www.facebook.com/sharer/sharer.php?u=' +
                                      feUrl +
                                      'product/' +
                                      $route.params.id
                                    "
                                  >
                                    <img
                                      src="@/assets/img/facebook-1.png"
                                      alt="Share Facebook"
                                    />
                                  </a>
                                  <a
                                    target="_blank"
                                    :href="
                                      'https://twitter.com/intent/tweet?text=' +
                                      feUrl +
                                      'product/' +
                                      $route.params.id
                                    "
                                  >
                                    <img
                                      src="@/assets/img/twitter-1.png"
                                      alt="Share Twitter"
                                    />
                                  </a>
                                  <a
                                    target="_blank"
                                    :href="
                                      'https://wa.me/?text=' +
                                      feUrl +
                                      'product/' +
                                      $route.params.id
                                    "
                                  >
                                    <img
                                      src="@/assets/img/whatsapp-sm.png"
                                      alt="Share Whatsapp"
                                    />
                                  </a>
                                  <a
                                    target="_blank"
                                    :href="
                                      'https://line.me/R/msg/text/?' +
                                      feUrl +
                                      'product/' +
                                      $route.params.id
                                    "
                                  >
                                    <img
                                      src="@/assets/img/line-sm.png"
                                      alt="Share Line"
                                    />
                                  </a>
                                </b-dropdown>
                              </div>
                            </div>
                          </form>
                          <!-- TODO OLD penukaran promise brand & product spec -->
                          <!-- <div class="row mt-4">
                            <div class="col-6">
                              <p class="facility">Free shipping</p>
                            </div>
                            <div class="col-6">
                              <p class="facility">Buy today, shipped today</p>
                            </div>
                            <div class="col-6">
                              <p class="facility">Always ready</p>
                            </div>
                            <div class="col-6">
                              <p class="facility">Money back guarantee</p>
                            </div>
                            <div class="col-6">
                              <p class="facility">
                                Certificate &amp; invoice included
                              </p>
                            </div>
                            <div class="col-6">
                              <p class="facility">0% Installment</p>
                            </div>
                          </div> -->
                          <div id="product-details-info" class="mt-3">
                            <div class="product-spec-title">PRODUCT DETAILS</div>
                            <div class="row product-details-info">
                              <label class="col-md-6 d-flex align-items-center">
                                Dimensions
                                <span class="ml-auto"> {{ product.dimension }} </span>
                              </label>
                              <label class="col-md-6 d-flex align-items-center">
                                Total Ct (gr)
                                <span class="ml-auto">{{
                                  product.sum_carat_of_gold
                                }}</span>
                              </label>
                              <label class="col-md-6 d-flex align-items-center">
                                Model
                                <span class="ml-auto">
                                  {{ product.category3_name }}
                                </span>
                              </label>
                              <label class="col-md-6 d-flex align-items-center">
                                Emas + Diamond (gr)
                                <span class="ml-auto">{{
                                  product.weight_of_gold_plus_diamond
                                }}</span>
                              </label>
                              <label class="col-md-6 d-flex align-items-center">
                                Kadar
                                <span class="ml-auto"> {{ product.kadar }} </span>
                              </label>
                              <label class="col-md-6 d-flex align-items-center">
                                Approximate Weight (gr)
                                <span class="ml-auto">{{ product.weight }} gr</span>
                              </label>
                            </div>
                          </div>

                          <div v-if="product.description" class="position-relative">
                            <input id="ch" type="checkbox" class="input-toggle-show">
                            <div id="product-description">
                              <div class="product-spec-title pt-3 pb-2">PRODUCT DESCRIPTION</div>
                              <div v-html="product.description"></div>
                            </div>
                            <label class="label-toggle-show mt-2" for="ch"></label>
                          </div>
                          <hr>
                          <!-- //* NEW UI Brand Product Detail (Test) -->
                          <div class="d-flex align-items-center mt-3">
                            <div class="product-detail-brand-logo-container">
                              <div class="product-detail-brand-logo-new bg-white" :style="{'--background' : `url('${product.brand_info.image_banner_square}')`}">
                                <div class="product-detail-brand-logo-prime" v-if="product.brand_info.check_prime_merchant"></div>
                              </div>
                            </div>
                            <div class="product-detail-brand-info ml-2">
                              <div class="product-detail-brand-name bold mb-1">{{ product.brand_info.name }}</div>
                              <div class="product-detail-brand-description">
                                <div class="product-detail-brand-rating" v-if="product.brand_info.average_brand_rating > 0" @click="changeNavbarByBrand('review')">
                                  <fawesome-pro variant="fas" icon="star" class="text-warning pr-1" />
                                  <span class="bold text-white">{{ product.brand_info.average_brand_rating.toFixed(1) }}/5</span>
                                </div>
                                <div v-if="product.brand_info.operational_time" class="d-flex mt-1 mt-lg-0">
                                  <b-img width="12" :src="require('@/assets/img/icon_time_white.svg')" alt="" class="icon-time-grey-color"></b-img>
                                  <span class="ml-1 text-grey">{{ setOperationalTimeText(getTodayOperationalTime(product.brand_info.operational_time)) }}</span>
                                </div>
                              </div>
                            </div>
                            <router-link
                              class="ml-auto btn btn-wz-clear bg-transparent px-lg-4 py-2 px-3"
                              :to="`/shop-by-brands/${$helpers.format.slug(`${product.brand_info.name}-official-store-${product.brand_info.id}`)}`"
                            >
                              View<br/>Brand
                            </router-link>
                          </div>
                          <!-- //* OLD UI Brand Product Detail -->
                          <!-- <div
                            class="d-flex align-items-center box-brand row no-gutters mt-3"
                            v-if="isLoaded && $store.getters.getBrandsList.length"
                          >
                            <div class="logos col-md-6">
                              <b-img-lazy
                                :src="$store.getters.getBrandsList.find(el => el.name == product.brand_name)?.image_url"
                                :title="$store.getters.getBrandsList.find(el => el.name == product.brand_name)?.name"
                                :alt="$store.getters.getBrandsList.find(el => el.name == product.brand_name)?.name"
                              />
                            </div>
                            <div class="text-center col-md-6">
                              <small class="text-grey">{{productCount}} product(s) available</small>
                              <br>
                              <router-link
                                :to="
                                  '/shop-by-brands/' +
                                  $store.getters.getBrandsList.find(
                                    (el) => el.name == product.brand_name
                                  )?.slug +
                                  '-official-store-' +
                                  $store.getters.getBrandsList.find(
                                    (el) => el.name == product.brand_name
                                  )?.id
                                "
                                class="btn btn-wz-outline w-lg-auto w-100 mt-0"
                                >SEE ALL PRODUCTS</router-link
                              >
                            </div>
                          </div> -->
                        </template>
                      </b-skeleton-wrapper>
                    
                      <div v-if="product && partOfBundle.length && product.product_type != 4">
                        <div class="product-spec-title pt-3"><img src="@/assets/icons/icon_related_bundle.svg" alt="" height="24"> Paket Bundling</div>
                        <div class="product-spec-body" v-for="(bundle, i) in partOfBundle" :key="i">
                          <hr class="my-1">
                          <!-- Change router link to div because different mapping from response get product detail for related bundle (error in product detail page) -->
                          <!-- <router-link :to="`/product/${bundle.slug}`" class="semi-subtitle invert">{{ bundle.name }}</router-link> -->
                          <div>{{ bundle.name }}</div>
                          <div class="row row-cols-3 row-cols-md-5 g-less flex-nowrap overflow-x-styled text-center">
                            <!-- <div class="col d-flex flex-column" @click="productToPass = bundle; bundlingIndex = j" v-b-modal.modal-add-to-bundle v-for="(choices, j) in bundle.product_bundle" :key="j"> -->
                            <div class="col d-flex flex-column" @click="productToPass = bundle; setBundleChoiceIndex(bundle.product_bundle, choices, j)" v-b-modal.modal-add-to-bundle v-for="(choices, j) in bundle.product_bundle" :key="j">
                              <template v-if="choices.submitted">
                                <b-img-lazy fluid-grow block :src="choices.submitted.product_images[0].thumbnail_image_url" :alt="choices.submitted.name" :title="choices.submitted.name" class="rounded cursor-pointer" />
                                <div class="s-less-line-height pt-2">
                                  <b class="d-block" :class="{'text-gold' : choices.submitted.price_display < choices.submitted.price}">{{ formatPrice(choices.submitted.price_display) }}</b>
                                  <span v-if="choices.submitted.discount_type == 2" class="badge badge-beige">{{ choices.submitted.discount }}%</span>
                                  <small v-if="choices.submitted.price_display < choices.submitted.price">
                                    <strike>{{ formatPrice(choices.submitted.price) }}</strike>
                                  </small>
                                  <small class="d-block">Color : {{ choices.submitted.product_detail_variance[0].color.name }}</small>
                                  <small class="d-block">Size : {{ choices.submitted.product_detail_variance[0].size.international }}</small>
                                </div>
                              </template>
                              <template v-else-if="choices.bundle_product.length == 1">
                                <div class="position-relative">
                                  <fawesome-pro variant="fad" icon="exclamation-triangle" class="position-absolute top-0 start-0 p-1" title="No variant selected" />
                                  <b-img-lazy fluid-grow block :src="choices.bundle_product[0].product_images[0].thumbnail_image_url" :alt="choices.bundle_product[0].name" :title="choices.bundle_product[0].name" class="rounded cursor-pointer" />
                                </div>
                                <div class="s-less-line-height pt-2 pb-1">
                                  <b class="d-block" :class="{'text-gold' : choices.bundle_product[0].price_display < choices.bundle_product[0].price}">{{ formatPrice(choices.bundle_product[0].price_display) }}</b>
                                  <span v-if="choices.bundle_product[0].discount_type == 2" class="badge badge-beige">{{ choices.bundle_product[0].discount }}%</span>
                                  <small v-if="choices.bundle_product[0].price_display < choices.bundle_product[0].price">
                                    <strike>{{ formatPrice(choices.bundle_product[0].price) }}</strike>
                                  </small>
                                </div>
                                <div class="btn-bundle-item-variance mt-auto">Select Variance</div>
                              </template>
                              <div v-else class="choose-bundle"></div>
                            </div>
                          </div>
                          <div class="row align-items-center justify-content-between">
                            <div class="col s-less-line-height">
                              <!-- <small><strike class="text-grey">{{ formatPrice(calculateBundleTotal(bundle, 'price').subtotal) }}</strike> Hemat <span class="text-success">{{ formatPrice(calculateBundleTotal(bundle, 'price').subtotal - calculateBundleTotal(bundle, 'price_display').total) }}</span></small> -->
                              <small>
                                <strike class="text-grey" v-if="bundle.discount_active_bundle && (calculateBundleTotal(bundle, 'price_display').total < calculateBundleTotal(bundle, 'price').subtotal)">{{ formatPrice(calculateBundleTotal(bundle, 'price').subtotal) }}</strike>
                                <span class="text-success" v-if="calculateBundleTotal(bundle, 'price').subtotal - calculateBundleTotal(bundle, 'price_display').total > 0"> Hemat {{ formatPrice(calculateBundleTotal(bundle, 'price').subtotal - calculateBundleTotal(bundle, 'price_display').total) }}</span> 
                              </small>
                              <p class="m-0 bold" :class="bundle.discount_active_bundle ? 'text-gold' : 'text-black'">{{ formatPrice(calculateBundleTotal(bundle, 'price_display').total) }}</p>
                              <small>(Total {{ bundle.product_bundle.length }} barang)</small>
                            </div>
                            <div class="col-md text-right">
                              <!-- <button class="btn btn-wz-buy-now btn-normal-text rounded-less px-3 py-2" @click="quickCheckout(bundle, bundle.product_detail_variance[0].id , 1)" :disabled="bundle.product_bundle.filter(el => el.submitted).length < bundle.product_bundle.length">
                                Buy Now
                              </button>
                              <button class="btn btn-wz-add-bag btn-normal-text rounded-less ml-1 px-3 py-2" @click="addToCart(bundle, bundle.product_detail_variance[0].id , 1)" :disabled="bundle.product_bundle.filter(el => el.submitted).length < bundle.product_bundle.length">
                                Add to Bag
                              </button> -->
                              <button class="btn btn-wz-buy-now btn-normal-text rounded-less px-3 py-2" @click="productToPass = bundle; bundlingIndex = 0" v-b-modal.modal-add-to-bundle>
                                Lihat Paket
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TODO OLD penukaran promise brand & product spec -->
      <!-- <div class="box-product-details pb-2 container">
        <div class="wrapper center-block">
          <div class="panel-group">
            <div class="panel-default">
              <h2>Details</h2>
              <div>
                <div class="panel-body">
                  <div class="row m-0">
                    <div class="col-md-4">
                      <div
                        class="d-flex align-items-center box-brand row"
                        v-if="$store.getters.getBrandsList.length"
                      >
                        <div class="logos col-md-6">
                          <b-img-lazy
                            :src="$store.getters.getBrandsList.find(el => el.name == product.brand_name).image_url"
                            :title="$store.getters.getBrandsList.find(el => el.name == product.brand_name).name"
                            :alt="$store.getters.getBrandsList.find(el => el.name == product.brand_name).name"
                          />
                        </div>
                        <div class="text-center col-md-6">
                          <small class="text-grey">{{productCount}} product(s) available</small>
                          <br>
                          <router-link
                            :to="
                              '/shop-by-brands/' +
                              $store.getters.getBrandsList.find(
                                (el) => el.name == product.brand_name
                              ).slug +
                              '-official-store-' +
                              $store.getters.getBrandsList.find(
                                (el) => el.name == product.brand_name
                              ).id
                            "
                            class="btn btn-wz-outline w-lg-auto w-100 mt-0"
                            >SEE ALL PRODUCTS</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div id="product-details-info" class="col-md-7 offset-md-1">
                      <h5>PRODUCT DETAILS</h5>
                      <div class="row product-details-info">
                        <label class="col-md-6 d-flex align-items-center">
                          Dimensions
                          <span class="ml-auto"> {{ product.dimension }} </span>
                        </label>
                        <label class="col-md-6 d-flex align-items-center">
                          Total Ct (gr)
                          <span class="ml-auto">{{
                            product.sum_carat_of_gold
                          }}</span>
                        </label>
                        <label class="col-md-6 d-flex align-items-center">
                          Model
                          <span class="ml-auto">
                            {{ product.category3_name }}
                          </span>
                        </label>
                        <label class="col-md-6 d-flex align-items-center">
                          Emas + Diamond (gr)
                          <span class="ml-auto">{{
                            product.weight_of_gold_plus_diamond
                          }}</span>
                        </label>
                        <label class="col-md-6 d-flex align-items-center">
                          Kadar
                          <span class="ml-auto"> {{ product.kadar }} </span>
                        </label>
                        <label class="col-md-6 d-flex align-items-center">
                          Approximate Weight (gr)
                          <span class="ml-auto">{{ product.weight }} gr</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row m-0 mt-2" v-if="product.description">
                    <div class="col-md-7 offset-md-5">
                      <h5 class="pt-3 pb-2">PRODUCT DESCRIPTION</h5>
                      <div v-html="product.description"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- // TODO brnad promises assets -->
      <!-- <div class="container">
        <div class="row row-cols-2 row-cols-lg-6 text-center flex-nowrap overflow-auto pb-2">
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">Free shipping</p>
          </div>
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">Buy today, shipped today</p>
          </div>
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">Always ready</p>
          </div>
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">Money back guarantee</p>
          </div>
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">Certificate &amp; invoice included</p>
          </div>
          <div class="col">
            <b-img-lazy height="100" class="w101h101" :src="require('@/assets/img/buyback.svg')"
              alt="Buyback Guarantee"
              title="Buyback Guarantee"
            />
            <p class="pt-3 mb-0">0% Installment</p>
          </div>
        </div>
      </div> -->

      <div class="box-product-review container">
        <div class="wrapper center-block pt-4">
          <b-skeleton-wrapper :loading="isLoading.productReview">
            <template #loading>
              <div class="d-flex align-items-center justify-content-between pb-2">
                <b-skeleton width="140px" height="42px"></b-skeleton>
                <b-skeleton type="button" width="200px" height="42px"></b-skeleton>
              </div>
              <div class="product-detail-review-card-skeleton">
                <b-skeleton height="70px" width="100%"></b-skeleton>
                <b-skeleton height="70px" width="100%"></b-skeleton>
              </div>
            </template>
            <div class="d-flex align-items-center pb-2">
              <h2 class="d-block m-0">Review</h2>
              <router-link
                :to="this.$route.path + '/review'"
                class="ml-auto btn btn-wz-outline mt-0 roboto"
                v-if="productReviews.length"
              >
                SEE ALL REVIEW ({{ productReviewsCount }})
              </router-link>
            </div>
            <VueSlickCarousel v-bind="sliderReviewSlickSettings" class="slider-review" v-if="productReviews.length">
              <div
                class="reviews"
                v-for="(review, i) in productReviews.slice(0, 4)"
                :key="i"
              >
                <div class="row blockquote review-item">
                  <div class="col-auto text-center">
                    <div class="reviewer rounded-circle bg-pink">
                      <h2 class="roboto align-items-center m-0 pt-3">
                        {{ review.reviewer_name.charAt(0) }}
                      </h2>
                    </div>
                  </div>
                  <div class="col-auto">
                    <fawesome-pro
                      v-for="i in 5" :key="i"
                      :variant="review.rating - i > -1 ? 'fas' : 'far'"
                      icon="star"
                      class="text-warning pr-1"
                    />
                    <div class="caption d-flex align-items-center">
                      <span>{{ review.reviewer_name }}</span>
                      <h4 class="roboto">
                        {{ formatDate(new Date(review.created_at)) }}
                      </h4>
                    </div>
                    <p class="review-text text-break">
                      {{ review.comment }}
                    </p>
                  </div>
                </div>
              </div>
            </VueSlickCarousel>
            <div class="text-center" v-else>
              <img src="@/assets/img/empty-review.png" alt="" width="200px"/>
              <h3 style="font-size: initial; letter-spacing: 1px; color: #0c0c0c;">
                There are no reviews yet for this product
              </h3>
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
      <!-- TODO Skeleton Loading Related Product After Product Box Skeleton Revamp Implemented -->
      <!-- <PuSkeleton v-if="!isLoaded" /> -->
      <template v-if="isLoaded">
        <RelatedProduct
          v-if="product.category3_id"
          :parentData="product.category3_id"
        />
        <MoreCollection class="my-5" v-if="product.available_collection" :collection="product.available_collection" />
        <FloatWhatsapp v-if="$store.getters.getUserData" />
        <Chat
          v-if="$store.getters.getUserData"
          :qbRegister="qbRegister"
          :product="product.id"
          :merchant="product.seller.id"
          ref="chat"
          @setDefaultQbRegister="qbRegister = undefined"
        />
      </template>
      <VueTour
        :tourName="tourName"
        :ref="tourName"
        :steps="steps"
        :callbacks="callbacks"
      />
      <ModalCategorySize :product="categorySize" />
      <ModalManageCatalog @refreshProduct="getProduct()" ref="modalManageCatalog" v-if="isMembershipActive && product" :existInGroup="product.exist_in_group" :productId="product.id.toString()"/>
      <ModalAddCatalogGroup @refreshCatalogGroup="$refs.modalManageCatalog.getCatalogGroupList()" />
      <ModalClosedShopInfo :notes="product.seller.closed_notes" v-if="isLoaded && product.seller.is_closed && product.seller.closed_notes"/>
      <ModalAddBundle ref="modalAddBundle" :index="bundlingIndex" :parent="productToPass" @quickCheckout="quickCheckout" @addToCart="addToCart" @nextIndex="bundlingIndex += 1" @previousIndex="bundlingIndex -= 1" @showModalCategorySize="categorySize = $event" />
      <ModalInstallmentCalculator :installmentCalculator="installmentCalculator" v-if="installmentCalculator.length" />
      <ModalAvailableVoucher :vouchers="product?.available_voucher" v-if="product?.available_voucher" />
    </section>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import axios from "axios";
import { eventBus } from "@/main";

import { toaster, cookieMan, scroll, mobile, userHelper, tourSettings, formatter, setMetaInfoProduct } from "@/_helpers";
import { qbHelper } from "@/_services";
import FloatWhatsapp from "@/components/mains/FloatWhatsapp";
import Chat from "@/components/mains/Chat";
import ImageZoom from '@/components/products/ImageZoom';
const UrlHandler = () => import('@/components/mains/UrlHandler');
const ModalCategorySize = () => import("@/components/modals/ModalCategorySize");
const ModalManageCatalog = () => import("@/components/modals/ModalManageCatalog");
const ModalAddCatalogGroup = () => import("@/components/modals/ModalAddCatalogGroup");
const ModalClosedShopInfo = () => import("@/components/modals/ModalClosedShopInfo");
const ModalAddBundle = () => import("@/components/modals/ModalAddBundle");
const ModalInstallmentCalculator = () => import("@/components/modals/ModalInstallmentCalculator");
const ModalAvailableVoucher = () => import("@/components/modals/ModalAvailableVoucher");
const RelatedProduct = () => import('@/components/mains/RelatedProduct');
const MoreCollection = () => import('@/components/mains/MoreCollection');
const RecommendedProducts = () => import('@/components/mains/RecommendedProducts');
import VueTour from "@/components/vuetour/VueTour";
import moment from "moment";

const initialData = () => ({
  isLoaded: false,
  isLoadingData: {
    product: false,
    // miniBanner: false,
    // relatedBundle: false,
    installmentCalculator: false,
    productReview: false,
  },

  breadCrumbs: {},
  colors: [],
  sizes: [],
  productVariantId: null,
  productQuantity: 1,
  variantStock: 0,
  categorySize: null,
  productType: null,
  product: null,
  productToPass: null,
  partOfBundle: [],
  productCount: 0,
  screenName: null,
  epochBatchPoCountdown: 0,
  currentTime: 0,

  // Product bundling vars here
  bundlingIndex: null,

  //* Product Installment Calculator
  installmentCalculator: [],

  //* For handling error isLoaded not updated on API error response
  isError: false,

  productReviews: [],
  productReviewsCount: null,

  //* For "bp_contact_exclusive_product" cookie
  selectedColor: null,
  selectedSize: null,
});

export default {
  name: "ProductDetail",
  components: {
    FloatWhatsapp,
    Chat,
    ImageZoom,
    UrlHandler,
    ModalCategorySize,
    ModalManageCatalog,
    ModalAddCatalogGroup,
    ModalClosedShopInfo,
    ModalAddBundle,
    ModalInstallmentCalculator,
    ModalAvailableVoucher,
    VueSlickCarousel,
    RelatedProduct,
    MoreCollection,
    RecommendedProducts,
    VueTour,
  },
  metaInfo() {
    return setMetaInfoProduct(this.screenName, this.screenName, this.product?.product_images[0].full_image_url, "product", this.product?.price_display);
  },
  data() {
    return Object.assign({
      isLoading: false,
      sliderNavThumbnailSlickSettings: {
        centerPadding: "0px",
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: this.$refs.sliderProduct,
        dots: false,
        arrows: false,
        focusOnSelect: true,
        vertical: true,
        verticalSwiping: true,
        responsive: [
          {
            breakpoint: 601,
            settings: {
              centerMode: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              vertical: false,
              verticalSwiping: false,
              arrows: false,
            },
          },
        ],
      },
      sliderProductDetailSlickSettings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: this.$refs.thumbnailProduct,
      },
      sliderReviewSlickSettings: {
        slidesToShow: 2,
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
          {
            breakpoint: 769,
            settings: {
              arrows: false,
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 569,
            settings: {
              centerMode: true,
              centerPadding: "2px",
              arrows: false,
              slidesToShow: 1,
              rows: 2,
            },
          },
        ],
      },
      qbRegister: undefined,
      token: this.$store.getters.getUserToken,
      tourName: "productDetailTour",
      callbacks: {
        onPreviousStep: this.onTourPreviousStep,
        onNextStep: this.onTourNextStep,
        onStart: this.onTourStart,
        onStop: this.onTourStop,
      },
      options: tourSettings,
      steps: [],
      announcementBanner: null,
    }, initialData());
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
    feUrl() {
      return process.env.VUE_APP_FE_URL;
    },
    isMembershipActive: function() {
      const resellerData = this.$store.getters.getResellerData
      return resellerData?.is_active && resellerData?.status_verify
    },
    userData() {
      return this.$store.getters.getUserData;
    },
    productId() {
      return this.$route.params?.id.split("-").pop();
    },
    lowestInstallment() {
      return this.installmentCalculator?.reduce((acc, curVal) => {
        if (curVal?.lowest_installment) acc = acc.lowest_installment.monthly_instalment < curVal.lowest_installment.monthly_instalment ? acc : curVal;
        return acc
      })
    },
    //? Get total price display (discounted product price) from selected product from each bundle option
    bundleTotalPriceDisplay() {
      return this.product.bundle_dynamic_price ? this.calculateSelectedBundleProduct() - this.discountAmount : this.product.price_display;
    },
    //? Get total price (original product price) from selected product from each bundle option
    bundleTotalPrice() {
      return this.calculateSelectedBundleProduct(false);
    },
    //? Get save amount if discount is active for bundle product
    bundleSaveAmount() {
      return this.bundleTotalPrice - this.bundleTotalPriceDisplay;
    },
    //? Calculate discount amount for bundle product
    discountAmount: function() {
      const product = this.product;
      if ((!product.discount_active_bundle || !product.discount) || !product.bundle_dynamic_price) return 0;

      let discount = 0;

      if (product.discount_type == 1) discount = product.discount ?? 0;
      else if (product.discount_type == 2) discount = this.calculateSelectedBundleProduct() * (product.discount / 100) ?? 0;

      return discount;
    },
  },
  watch: {
    "$route.params"() {
      Object.assign(this.$data, initialData());
      this.getProduct();
      this.getBundledIn();
      this.getInstallmentCalculator();
      this.getProductReview();
    },  
    currentTime(currentTime) {
      const product = this.product
      if(!product.batch_po) return

      const startTime = product.start_date_po;
      const endTime = product.end_date_po;

      if (currentTime < startTime)
        this.epochBatchPoCountdown = startTime - currentTime
      else if (currentTime > startTime && currentTime < endTime)
        this.epochBatchPoCountdown = endTime - currentTime
    },
    // old function change product price for BP user
    // "$store.getters.getResellerData"(resellerData) {
    //   if (resellerData.status_verify) {
    //     this.token = this.$store.getters.getUserToken;
    //     this.getProduct();
    //   }
    // },
  },
  async mounted() {
    if (!cookieMan.get(this.tourName).length && !this.$helpers.other.isCrawlerBot()) {
      this.setProductDetailTourStep();
      scroll.scrollActive(false);
    }
    const productPromise = await Promise.all([
      this.getMiniBanner('productdetail'),
      this.getProduct(),
      this.getBundledIn(),
      this.getInstallmentCalculator(),
      this.getProductReview()
    ])

    if (productPromise) {
      setTimeout(() => {
        window.prerenderReady = true
      }, 2000)
    }

    // this.getMiniBanner('productdetail')
    // this.getProduct();
    // this.getBundledIn();
    // this.getInstallmentCalculator();
    // this.getProductReview();
  },
  created() {
    eventBus.$on('addProductToCartAfterLogin', (data) => {
      this.addToCart(data.product, data.variant , data.quantity);
    })
  },
  destroyed() {
    eventBus.$off('addProductToCartAfterLogin'); // * Destroy listener to prevent the listener stacking (cause multiple add to cart)
  },
  methods: {
    contactReseller() {
      // const bp_contact_exclusive_product = {
      //   name: this.product.name,
      //   ...(this.selectedColor ? { color: this.selectedColor } : undefined),
      //   ...(this.selectedSize ? { size: this.selectedSize } : undefined)
      // }
      // cookieMan.put('bp_contact_exclusive_product', JSON.stringify(bp_contact_exclusive_product));

      //* 'Whiz Signature Exclusive Product Link' cookie
      cookieMan.put('wsepl', window.location.href);
      this.$router.push("/find-our-bp/bp-list");
    },
    setSelectedColor(e) {
      this.selectedColor = this.product.product_detail_variance.find(el => el.color.id == e.target.value)?.color?.name;
    },
    setSelectedSize(e) {
      this.selectedSize = this.product.product_detail_variance.find(el => el.id == e.target.value)?.size?.international;
    },
    onMouseMove(event) {
      event.target.style.setProperty("--position-x", -event.offsetX / 2 + "px");
      event.target.style.setProperty("--position-y", -event.offsetY / 2 + "px");
    },
    handleProductHistory(product) {
      const history = JSON.parse(cookieMan.get("fe_history_product") || "[]");
      const index = history.findIndex((el) => el.id == product.id);
      const push = {
        id: product.id,
        name: product.name,
        slug: this.$route.params.id,
        image:
          product.product_images[0].thumbnail_image_url ||
          product.product_images[0].medium_image_url,
      };
      if (index >= 0)
        history.splice(index, 1).unshift(push);
      else {
        history.unshift(push);
        if (history.length > 6) history.pop();
      }
      cookieMan.put("fe_history_product", JSON.stringify(history));
    },
    async addToWishlist(product) {
      if (this.isLoggedIn) {
        try {
          const res = await this.$api.user.addToWishlist(product)
          if (res.status === 200) {
            toaster.make(product.name + 'has been added to your wishlist', 'success')
            this.$api.user.getWishlist()
          } else toaster.make(res.data.message, 'danger')
        } catch (e) {
          if (e.response) toaster.make(e.response, 'danger')
          console.error(e);
        }
      } else {
        this.$bvModal.show("modalLogin");
      }
    },
    async removeFromWishlist(product) {
      try {
        const res = await this.$api.user.removeFromWishlist(product.id)
        if (res.status === 200) {
          const data = this.$store.getters.getWishlist;
          data.splice(data.findIndex((el) => el.id == product.id), 1);
          this.$store.dispatch("setWishlist", data);
          toaster.make(product.name + " has been removed from your WishLiz");
        } else toaster.make(res.data.message, 'danger');
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger');
        console.error(e);
      }
    },
    triggerColor() {
      if (this.sizes.length == 0) {
        document.querySelector(".panel-warning").classList.add("active");
        setTimeout(
          () =>
            document.querySelector(".panel-warning").classList.remove("active"),
          3000
        );
      }
    },
    colorChange(color) {
      this.productVariantId = null
      if (this.productType.indexOf("Ring") >= 0) {
        this.sizes = this.product.product_detail_variance.reduce((arr, el) => {
          if (!el) return arr
          else if (el.color.id == color.target.value && !el.is_deleted)
            arr.push({
              international: el.size.international,
              product_variant_id: el.id,
              total_stock: el.total_stock,
            })
          return arr
        }, [])
        // https://stackoverflow.com/a/45784629
        this.variantStock = this.sizes.reduce(
          (el, { total_stock }) => el + total_stock,
          0
        );
        document.getElementById("size-select").value = 0;
      } else {
        const variance = this.product.product_detail_variance.find(
          (el) => el.color.id == color.target.value
        );
        this.productVariantId = variance.id;
        this.variantStock = variance.total_stock;
      }
      // Vue tour trigger next step
      const vueTour = this.$refs[this.tourName];
      if (vueTour.isRunning()) vueTour.nextStep();
    },
    sizeChange(size) {
      this.productVariantId = parseInt(size.target.value);
      this.variantStock = this.product.product_detail_variance.find(
        (el) => el.id == parseInt(size.target.value)
      ).total_stock;
      // Vue tour trigger next step
      const vueTour = this.$refs[this.tourName];
      if (vueTour.isRunning()) vueTour.nextStep();
    },
    handleSubmit() {
      // if (!this.$store.getters.getUserData) this.$bvModal.show('modalLogin')
      if (this.product.checkout_type == 0) this.addToCart(this.product, this.productVariantId, this.productQuantity)
      else {
        const product = Object.assign(this.product, {
          quantity: this.productQuantity,
        });
        localStorage.setItem("redeemItem", JSON.stringify(product));
        this.$router.push("/checkout-redeem");
      }
    },
    calculateSelectedBundleProduct(isPriceDisplay = true) {
      return this.product?.product_bundle?.filter(el => el.submitted).reduce((sumBundlePrice, { bundle_product }) => sumBundlePrice + bundle_product.reduce((sumProductPrice, { price, price_display, selected }) => sumProductPrice + (selected ? isPriceDisplay ? price_display : price : 0), 0), 0)
    },
    bundleHandler(bundle, quantity) {
      return bundle.product_type == 4 ? bundle.product_bundle.map(el => ({
        product_detail_variance_id: el.submitted.selectedVariant,
        quantity: quantity,
        bundle_id: el.id,
      })) : null
    },
    async quickCheckout(bundle, variantId, quantity) {
      this.isLoading = true
      this.$refs.modalAddBundle.isQuickCheckoutLoading = true; // * Set Quick Checkout for Add to Bag in Modal Add Bundle
      try {
        const res = await this.$api.user.quickCheckout(variantId, quantity, this.bundleHandler(bundle))
        if (res.status === 200) {
          const { data } = res.data
          this.logToAnalytics(bundle, variantId, quantity)
          localStorage.setItem('checkoutData', JSON.stringify(await userHelper.setCartList(data)))
          this.$router.push('/checkout')
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
      this.$refs.modalAddBundle.isQuickCheckoutLoading = false; // * Set Quick Checkout for Add to Bag in Modal Add Bundle
    },
    async addToCart(product, variantId, quantity) {
      if (!this.isLoggedIn) {
        // * Set product data to vuex store (for add to cart automatically after login)
        this.$store.dispatch("setProductAddToCartAfterLogin", {
          product: product,
          variant: variantId,
          quantity: quantity
        })
        return this.$bvModal.show('modalLogin')
      }
      this.isLoading = true
      if (this.$refs.modalAddBundle) this.$refs.modalAddBundle.isAddCartLoading = true; // * Set Loading Spinner for Add to Bag in Modal Add Bundle
      try {
        const res = await this.$api.user.addToCart(variantId, quantity, this.bundleHandler(product));
        if (res.status == 200) {
          toaster.make(product.name + " has been added to your shopping bag", "success");
          // when user add product to cart
          this.logToAnalytics(product, variantId, quantity);
          userHelper.setCartList();
        } else toaster.make(res.data.message, "danger");
      } catch (e) {
        if (e.response) toaster.make(e.response.data.message, "danger");
        console.error(e);
      }
      this.$bvModal.hide('modal-add-to-bundle');
      if (this.$refs.modalAddBundle) this.$refs.modalAddBundle.isAddCartLoading = false;// * Set Loading Spinner for Add to Bag in Modal Add Bundle
      this.isLoading = false
    },
    logToAnalytics(product, variantId, quantity) {
      this.$firebaseLogEvent('add_to_cart', {
        currency: "IDR",
        items: [
          {
            item_id: product.id.toString(),
            item_name: product.name,
            item_brand: product.brand_name,
            item_category: product.category3_name,
            item_variant: variantId,
            currency: "IDR",
            price: product.price_display,
            quantity,
            size: product.product_detail_variance.find(
              (el) => el.id == variantId
            ).size.international,
            color: product.product_detail_variance.find(
              (el) => el.id == variantId
            ).color.name,
          },
        ],
        value: product.price_display,
      });
      if(!this.apiUrl.includes('staging')) {
        this.$analytics.fbq.event("AddToCart", {
          content_ids: product.id,
          content_type: "product",
          content_name: product.name,
          content_category: product.category3_name,
          value: product.price_display,
          currency: "IDR",
        });
      }
    },
    addQuantity() {
      if (this.productQuantity < this.variantStock) {
        this.productQuantity++;
      }
    },
    reduceQuantity() {
      if (this.productQuantity - 1 > 0) {
        this.productQuantity--;
      }
    },
    syncSliders(currentPosition, nextPosition) {
      this.$refs.thumbnailProduct.goTo(nextPosition);
      this.$refs.sliderProduct.goTo(nextPosition);
    },
    async getMiniBanner(type) {
      try {
        const res = await this.$api.cms.getMiniBanner(type)
        if (res.status === 200) this.announcementBanner = res.data.data
        else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getProduct() {
      try {
        this.isLoadingData.product = true;
        const res = await this.$api.product.getProductDetail(this.productId);
        if (res.status === 200) {
          this.setProduct(res.data);
          this.handleProductHistory(res.data.data);
          this.setBreadCrumbs(res.data.data.category3_slug);
          this.firebaseViewItem(res.data.data);
          this.isLoaded = true;
          this.isLoadingData.product = false;
        } else {
          this.isError = true;
          this.isLoadingData.product = false;
          toaster.make(res.data.message, "danger")
        }
      } catch (e) {
        this.isError = true;
        if (e.response) toaster.make(e.response, "danger");
        console.error(e);
      }
    },
    setProduct(data) {
      const product = data.data;
      if (!product.is_active || product.is_deleted) this.$router.push("/");

      // For SEO
      const seo = [product.brand_name, product.name, "Whizliz"].join(" - ");
      this.screenName = seo;

      if (product.product_type == 4 && product.product_bundle?.length) {
        product.product_bundle.map(el => {
          el.bundle_product.map(rel => {
            rel.selectedVariant = null
            rel.selectedColor = null
            rel.selected = false
            return rel
          })
          el.submitted = null
        })
        this.productToPass = product
      }

      this.product = product;
      this.variantStock = product.total_stock;

      // Group unique product colors
      this.colors = this.groupProductColors(product)
      
      const isRing = this.detectRingSizes(product)
      this.productType = isRing ? "Ring" : product.category3_name;
      if (/fine|gift|bundle/i.test(this.productType) && product.product_detail_variance.length == 1) this.productVariantId = product.product_detail_variance[0].id

      // Count all products by this brand
      this.countProduct(product.brand_name)

      // if product is batch_po set current time to update countdown duration per seconds
      if (product.batch_po) {
        this.setCurrentTime();
        setInterval(this.setCurrentTime.bind(this), 1000);
      }
      // if product is batch_po set current time to update countdown duration per seconds
      if (product.batch_po) {
        this.setCurrentTime();
        setInterval(this.setCurrentTime.bind(this), 1000);
      }
      // Start Product Detail Tour Guide after set product
      if (!cookieMan.get(this.tourName).length && this.productType != "Bundle" && !this.$helpers.other.isCrawlerBot()) this.startProductDetailTour();
      else scroll.scrollActive(true);
    },
    async getBundledIn() {
      const productId = this.$route.params.id.split('-').pop()
      try {
        const res = await this.$api.product.getBundledIn(productId);
        if (res.status === 200) {
          const { data } = res.data
          this.partOfBundle = data.map(bundle => {
            const primaryProduct = bundle.product_bundle.findIndex(el => el.product_list_ids?.includes(productId) ?? true)
            bundle.product_bundle = bundle.product_bundle.splice(primaryProduct, 1).concat(bundle.product_bundle.sort((a, b) =>
              (a.bundle_product.length == 1 ? 0 : 1) - (b.bundle_product.length == 1 ? 0 : 1)
            )).map((choice, i) => {
              if (i == 0) {
                choice.product_list_ids = `[${productId}]`
                choice.bundle_product = choice.bundle_product.filter(el => el.id == productId)
              }
              choice.bundle_product.map(el => {
                el.selectedVariant = null
                el.selectedColor = null
                el.selected = false
              })
              choice.submitted = null
              return choice
            })
            return bundle
          })
        } else toaster.make(res.data.message, 'danger')
      } catch (e) {
        if (e.response) toaster.make(e.response, 'danger')
        console.error(e);
      }
    },
    async getProductReview() {
      try {
        this.isLoadingData.productReview = true;
        const res = await this.$api.product.getProductReviewList(this.productId);
        if (res.status === 200) {
          this.productReviewsCount = res.data.meta.total;
          this.productReviews = res.data.data;
        }
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoadingData.productReview = true;
      } catch (error) {
        this.isLoadingData.productReview = false;
        console.error(error);
      }
    },
    calculateBundleTotal(bundle, key) {
      //* OLD subtotal / total of product.price (original price) calculation
      // const subtotal = bundle.bundle_dynamic_price
      //   ? bundle.product_bundle.map(choice => choice.bundle_product.length == 1 ? choice.bundle_product[0] : choice.submitted).reduce((sum, el) => sum + (el?.status_purchaseable ? el[key] : 0), 0)
      //   : bundle[key]
      //* NEW subtotal / total of product.price (original price) calculation (always sum product.price for static / dynamic price bundle)
      const subtotal = bundle.product_bundle.map(choice => choice.bundle_product.length == 1 ? choice.bundle_product[0] : choice.submitted).reduce((sum, el) => sum + (el?.[key] ? el?.[key] : 0), 0);

      //* OLD total payment calculation
      // const total = subtotal - (bundle.discount_type == 2 ? bundle.price - bundle.price_display : bundle.discount_type == 1 ? bundle.discount : 0)
      //* NEW total payment calculation (manual sum total payment for dynamic price bundle)
      let total = 0;
      let discount = 0;
      if (bundle.discount_active_bundle && bundle.discount && bundle.bundle_dynamic_price) discount = bundle.discount_type == 1 ? bundle.discount : bundle.product_bundle.map(choice => choice.bundle_product.length == 1 ? choice.bundle_product[0] : choice.submitted).reduce((sum, el) => sum + (el?.status_purchaseable ? el.price_display : 0), 0) * (bundle.discount / 100);
      total = bundle.bundle_dynamic_price ? subtotal - discount : bundle.price_display;
      return { subtotal, total }
    },
    groupProductColors(product) {
      // https://stackoverflow.com/a/54088854
      return [...product.product_detail_variance.map(el => el.color).reduce((a, b) => {
        a.set(b.id, b)
        return a
      }, new Map()).values()]
    },
    detectRingSizes(product) {
      return product.product_detail_variance
        .reduce((arr, el) => {
          const { international } = el?.size
          if (international) arr.push(international)
          return arr
        }, [])
    },
    setBreadCrumbs(slug) {
      axios
        .get(this.apiUrl + "users/cms/category_parent/" + slug, {
          headers: {
            api_key: this.apiKey,
          },
        })
        .then((response) => (this.breadCrumbs = response.data.data))
        .catch((error) => console.error(error));
    },
    async countProduct(brand) {
      if (this.$store.getters.getBrandsList.length == 0) return setTimeout(() => this.countProduct(brand), 100)
      const filter = {
        logic: "and",
        filters: [
          {
            property: "brand.id",
            operator: "equals",
            values: [
              {
                value: this.$store.getters.getBrandsList.find(
                  (el) => el.name == brand
                )?.id,
              },
            ],
          },
        ],
      };
      try {
        const res = await this.$api.product.getProductList(0, 1, null, filter)
        if (res.status === 200) {
          return this.productCount = res.data.meta.total
        } else toaster.make(res.data.message, "danger")
      } catch (e) {
        toaster.make(e.response, "danger");
        console.error(e);
      }
    },
    simpleSlug(slug1, slug2, slug3, id) {
      const slug_3 = slug3.replace(`${slug2}-`, "");
      const slug_2 = slug2.replace(`${slug1}-`, "");
      return `/products/${slug1}/${slug_2}/${slug_3}-${id}`;
    },
    async share() {
      await navigator.clipboard.writeText(
        this.feUrl + "product/" + this.$route.params.id
      );
      toaster.make(
        "URL to this page has been copied to your clipboard",
        "success"
      );
    },
    formatPrice(value, currency = 'Rp') {
      // return value
      //   ? value.toLocaleString("id", { maximumFractionDigits: 2 })
      //   : value;
      return this.$helpers.format.price(value, currency)
    },
    // Format merchant store close end date
    formatEndDateClose(value) {
      moment.locale("id"); // Set date locale to indonesia
      return value ? moment.unix(value/1000).add(1, "days").format("DD MMMM YYYY") : value;
    },
    // Format review date
    formatDate(value) {
      return formatter.epochMsToLocalTime(value, "DD MMMM YYYY");
    },
    replaceParent(img) {
      img.target.parentNode.setAttribute("img-load-fail", true);
    },
    replaceByDefaultImage(img) {
      img.target.src = require("@/assets/img/product-default.png");
      img.target.classList.add("default-image");
    },
    firebaseViewItem(data) {
      // when user follows a link that goes directly to a product page.
      this.$firebaseLogEvent("view_item", {
        currency: "IDR",
        items: [
          {
            item_id: data.id.toString(),
            item_name: data.name,
            item_brand: data.brand_name,
            item_category: data.category3_name,
            currency: "IDR",
            price: data.price_display,
            quantity: data.total_stock,
          },
        ],
        value: data.price_display,
      });
    },
    chatProduct() {
      const token = this.$store.getters.getUserToken;
      if (token) {
        const chat_window = document.getElementById("float-chat-window");
        const chat_button = document.getElementById("float-whatsapp");

        if (!chat_window.classList.contains("active")) {
          chat_window.classList.toggle("active");
          chat_button.classList.toggle("active");
        }
        // If user don't have quickblox account
        if (!this.$store.getters.getUserData.quickblox_id) this.qbRegister = true;
        // If user already have quickblox account
        else this.createDialogProduct();
      } else this.$bvModal.show("modalLogin");
    },
    createDialogProduct() {
      this.$store.dispatch({
        type: "setIsLoading",
        key: "dialogList",
        value: true,
      });
      qbHelper.createDialog(
        this.product.seller.id, // merchant id 
        this.product.id, // product id
        "product" // type for prepare chat member merchant
      );
    },
    countdownDuration(time) {
      const duration = moment.duration(this.epochBatchPoCountdown, "milliseconds");
      // if days/hours/minutes < 10 add leading zeros
      const daysLeft = duration.asDays() >= 10 ? parseInt(duration.asDays()) : "0" + parseInt(duration.asDays()).toString();
      const hoursLeft = duration.hours() >= 10 ? duration.hours() : "0" + duration.hours().toString();
      const minutesLeft = duration.minutes() >= 10 ? duration.minutes() : "0" + duration.minutes().toString();
      if(time == 'days') return daysLeft
      if(time == 'hours') return hoursLeft
      if(time == 'minutes') return minutesLeft
    },
    setCurrentTime() {
      this.currentTime = Math.round(Date.now());
    },
    async downloadProductBrochure(productId) {
      try {
        const data = { product_id: productId }
        const res = await this.$api.user.downloadProductBrochure(data)
        if (res.status === 200) window.open(res.data.data)
        else toaster.make(res.data.message, "danger")
      } catch (error) {
        console.error(error)
      }
    },
    // Handler for opening which bundle choice index between the submitted choice(use index) or not yet submitted choice(use notSubmittedLastIndex) 
    setBundleChoiceIndex(bundle, choices, index) {
      const notSubmittedLastIndex = bundle.findIndex(el => !el.submitted);
      this.bundlingIndex = choices.submitted ? index : notSubmittedLastIndex;
    },
    showInstallment(product) {
      return product.checkout_type != 1 && !/fine/i.test(product.category3_name) && !product.bundle_dynamic_price
    },
    // Vue Tour Methods START
    setProductDetailTourStep() {
      this.steps.push(
        // Product Stock
        {
          target: "#product-stock",
          header: {
            title: "In Stock",
          },
          content: `Cek dulu stock kita yuk, biar kamu tahu ketersediaannya :)`,
          params: {
            placement: mobile.isMobile() ? "top" : "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Pick Color
        {
          target: "#pick-color",
          header: {
            title: "Color",
          },
          content: `Tahap ini jangan sampe kelewatan yah, pilih dulu warna perhiasan yang kamu mau.`,
          params: {
            placement: mobile.isMobile() ? "top-end" : "top",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Pick Size
        {
          target: "#pick-size",
          header: {
            title: "Size",
          },
          content: `Pastikan kamu memlikih size yang tepat untuk cincin kamu. Tanya CS kita untuk tahu cara mengukur Cincin.`,
          params: {
            placement: mobile.isMobile() ? "top-start" : "top",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Pick Quantity
        {
          target: "#pick-quantity",
          header: {
            title: "Quantity",
          },
          content: `Hm, beli berapa ya? 1, 2 3? Jangan lupa tulis yaa`,
          params: {
            placement: "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Product Details Info
        {
          target: "#product-details-info",
          header: {
            title: "Product Detail",
          },
          content: `Nah page ini penting buat kamu ketahui supaya tau detail produk yang kamu beli.`,
          params: {
            placement: "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Add to bag
        {
          target: "#add-to-bag",
          header: {
            title: "Add to Bag",
          },
          content: `Eits, jangan lupa masukin ke keranjang juga yaa!`,
          params: {
            placement: "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Buy now
        {
          target: "#buy-now",
          header: {
            title: "Buy Now",
          },
          content: `Selain itu kamu juga bisa langsung beli loh`,
          params: {
            placement: "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        },
        // Chat Product
        {
          target: "#chat-product",
          header: {
            title: "Chat Merchant",
          },
          content: `Ada pertanyaan buat produk yang kamu incer? Langsung chat merchant kita, dan kamu akan langsung dibantu :)`,
          params: {
            placement: "bottom",
            enableScrolling: false,
            enableButton: tourSettings.enabledButtons,
          },
        }
      );
    },
    onTourStart() {
      const isMobile = mobile.isMobile();
      const yOffset = isMobile ? 15 : 50;

      this.$firebaseLogEvent("begin_tutorial", {
        tutorial_name: "Product Detail Tour",
        tutorial_page: "Product Detail",
      });

      scroll.scrollTo(this.steps[0].target.split("#")[1], yOffset);
    },
    onTourStop() {
      if (this.$refs[this.tourName].isRunning() == false) return;

      const currentStep = this.$tours[this.tourName].currentStep;
      const isSkip = currentStep < this.steps.length - 1;

      this.$firebaseLogEvent(isSkip ? "skip_tutorial" : "complete_tutorial", {
        tutorial_name: "Product Detail Tour",
        tutorial_page: "Product Detail",
      });

      scroll.scrollActive(true)
      // If tour guide stopped in product detail page > add cookie productDetailTour
      setTimeout(() => {
        if (/\/product\//.test(this.$router.currentRoute.path)) document.cookie = `${this.tourName}=true; expires=Mon, 31 Dec 2040 12:00:00 GMT; path=/`;
      }, 1000);
    },
    onTourPreviousStep(currentStep) {
      const isMobile = mobile.isMobile();
      const yOffset = isMobile ? 10 : 50;

      scroll.scrollTo(this.steps[currentStep - 1].target.split("#")[1], yOffset);
    },
    onTourNextStep(currentStep) {
      const isMobile = mobile.isMobile();
      const yOffset = isMobile ? 10 : 50;

      scroll.scrollTo(this.steps[currentStep + 1].target.split("#")[1], yOffset);
    },

    startProductDetailTour() {
      // if product type is fine gold -> remove pick color step
      if (this.productType == "Fine Gold") {
        const index = this.steps.findIndex((el) => el.target == "#pick-color");
        this.steps.splice(index, 1);
      }
      // if product type isn't ring -> remove pick size step
      if (this.productType != "Ring") {
        const index = this.steps.findIndex((el) => el.target == "#pick-size");
        this.steps.splice(index, 1);
      }

      setTimeout(() => {
        this.$refs.productDetailTour.startTour();
      }, 500);
    },
    // Vue Tour Methods END

    async getInstallmentCalculator() {
      try {
        const res = await this.$api.product.getInstallmentCalculator(this.productId);
        if (res.status === 200) this.setInstallmentCalculator(res.data.data);
      } catch (error) {
        console.error(error);
      }
    },
    setInstallmentCalculator(data) {
      if (data.length == 0) return;
      this.installmentCalculator = data;
      for (const key of this.installmentCalculator) {
        if (key.details.length) key.lowest_installment = key.details.reduce((accDetail, currDetail) => {
            accDetail = accDetail.monthly_instalment < currDetail.monthly_instalment ? accDetail : currDetail; 
            return accDetail;
          })                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         
      }
    },
    getTodayOperationalTime(data) {
      //* Ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getDay
      const currentDate = new Date().getDay();
      if (currentDate == 0) return data.find(el => el.id == 7);
      else return data.find(el => el.id == currentDate);
    },
    setOperationalTimeText(currOpTime) {
      if (!currOpTime.open && !currOpTime.closed) return "Libur";
      else if (currOpTime.open == "00:00" && currOpTime.closed == "23:59") return "Buka 24 Jam";
      else return `${currOpTime.open} - ${currOpTime.closed} WIB`
    },
    setProductImgAltText(product) {
      return `${product.brand_name} ${product.name}${product.kadar > 0 ? ` with purity of ${product.kadar}` : ''}`
    },
  },
};
</script>

<style scoped>
div >>> p {
  margin: 0;
  color: #828282;
}
.radio-container input:checked ~ .checkmark {
  --background: #fbd3c7;
  background: var(--background);
  border: 1px solid black;
}
.mw-95px {
  max-width: 95px !important;
}
.po-status-badge {
  font-size: 14px;
}


.product-spec-title {
	line-height: 23px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.product-spec-body {
  font-size: 10pt;
}
@media (min-width: 768px) {
  .product-spec-body {
    font-size: 11pt;
  }
}

.choose-bundle {
  position: relative;
  padding-top: 100%;
  cursor: pointer;
}
.choose-bundle::after {
  content: '\f067';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  font-size: 2em;
  position: absolute;
  inset: 0;
  background: #f3f3f5;
  border: 1px solid #c7c7c5;
  border-radius: .5rem;
  color: #c7c7c5;
  padding: .25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#product-description {
  max-height: 250px;
  overflow: hidden;
}
input.input-toggle-show {
  display: none;
}
label.label-toggle-show {
	color: #d89700;
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
  cursor: pointer;
}
label.label-toggle-show:after {
  content: "Show More";
}
input.input-toggle-show:checked ~ label.label-toggle-show:after {
  content: "Show Less";
}
input.input-toggle-show:checked ~ div#product-description {
  max-height: 100%;
}
.btn-block+.btn-block {
  margin-top: 0;
}
.btn-bundle-item-variance {
  border-radius: .5rem;
  border: 1px solid #c7c7c5;
  color: #c7c7c5;
  background: #f3f3f5;
  text-align: center;
  padding: 6px 10px;
  font-size: 13px;
}
.custom-font-11px {
  font-size: 11px !important;
}
.custom-font-24px {
  font-size: 24px !important;
}
.product-detail-voucher-badge {
  display: flex;
  align-items: center;
  max-width: 415px;
  background: #FFF;
  border-radius: .45rem;
  box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
  -webkit-box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
  -moz-box-shadow: 4px 4px 10px 0px rgba(210, 210, 210, 0.75);
}
.product-detail-voucher-badge img {
  border-radius: .45rem;
  width: 70px;
  height: 70px;
  padding: 1rem;
  background: linear-gradient(to top, #a38041, #fcc351);
}
.product-detail-review-card-skeleton {
  display: flex;
  gap: .5rem;
  justify-content: space-between;
}
.greatest-voucher-card-skeleton {
  max-width: 415px
}
@media only screen and (min-width: 992px) {
  .skeleton-product-thumbnails > div {
    width: 100px;
  }
  .skeleton-product-thumbnails > div:not(:first-of-type) {
    margin-top: .25rem;
  }
}
@media only screen and (max-width: 992px) {
  .skeleton-product-thumbnails > div {
    width: 100%;
  }
  .skeleton-product-thumbnails > div:not(:last-of-type) {
    margin-right: .25rem;
  }
}

</style>
