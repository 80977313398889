<template>
  <div class="box-figure" @click="toggleZoom" @mousemove="zoomMove" @mouseleave="deactivateZoom"
    :style="{'--imgSrc' : `url('${src}')`}"
  >
    <b-img-lazy rel="preload" fluid-grow
      :src="src"
      :alt="alt"
      :title="alt"
      @error.native="replaceParent"
    />
    <b-img :src="active_promo_badge_url" alt="" class="promo-badge-img w-100" v-if="active_promo_badge_url" />
    <div></div>
  </div>
</template>
<script>
export default {
  name: 'ImageZoom',
  props: ['src', 'alt', 'active_promo_badge_url'],
  methods: {
    toggleZoom(event) {
      event.target.parentNode.classList.toggle('hovered')
    },
    zoomMove(event) {
      event.target.parentNode.style.setProperty("--position-x", -event.offsetX / 2 + "px");
      event.target.parentNode.style.setProperty("--position-y", -event.offsetY / 2 + "px");
    },
    deactivateZoom(event) {
      event.target.classList.remove('hovered')
    },
    replaceParent(img) {
      img.target.src = require('@/assets/img/product-default.png')
      img.target.classList.add('default-image')
      img.target.parentNode.setAttribute("img-load-fail", true);
    },
  },
}
</script>
<style scoped>
</style>